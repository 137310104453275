import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return(
      <Layout metadata={this.props.data.site.siteMetadata} currentPage="Benefits">
        <div>
          <h1>Benefits</h1>
          <div style={styles.infoContainer}>
            <p>One of the questions I get asked most is what are the benefits of psychotherapy, 
              so I decided to dedicate a page of my website to the answer.</p>
            <p>It is very important to talk to someone. Sometimes we can bottle up feelings and emotions. 
              Talking to someone could help us to release that stress. If you don’t have a family member 
              or friend that you feel you can talk to about your feelings and emotions they could remain 
              built up. Psychotherapy allows you to talk to someone who listens in a quiet and 
              confidential space and it could allow you to release the stress of everyday life.</p>
            <p>Psychotherapy could help you to take control of your life. You could see your sleeping 
              patterns improve as you go to bed each evening with a clear, stress free mind. You could 
              have a quality of life that you thought was never possible. You could start moving forward 
              in your life and you could stop dwelling on past events. You could feel much happier in 
              yourself and you could have the resources to manage your emotions.</p>
            <p>Of course, the more work you put in and the more seriously you take the therapy the more 
              affective it could be. With that in mind psychotherapy could be a great option for you to 
              change your life for the better.</p>
        </div>
      </div>
    </Layout>
    )
  }
}

const styles = {
  infoContainer: {
    overflow: 'auto'
  },
  info: {
    float: 'left',
    margin: '20px 20px',
  },
  mapContainer: {
    display: 'block',
    marginTop: 40
  },
  map: {
    width: '100%',
    height: 400,
    border: 'none'
  }
}

export default Benefits

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`